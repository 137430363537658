import { memo } from 'react';

import { ReactComponent as SuccessIcon } from 'assets/icons/Success.svg';
import { useStyles } from 'components/toast/styles';
const TypeIcon = ({ type }) => {
  const { type: typeClass } = useStyles();
  const typeIcons = {
    success: <SuccessIcon />,
  };
  return <div className={typeClass}>{typeIcons[type]}</div>;
};

export default memo(TypeIcon);
