import { memo } from 'react';
import { Menu, MenuButton, MenuItems } from '@headlessui/react';
import clsx from 'clsx';

import { ReactComponent as ArrowDropIcon } from 'assets/icons/Arrow Drop.svg';

import { useStyles } from './styles';

const Dropdown = ({ children, trigger, withArrow }) => {
  const { dropdownTrigger, dropdownContent, arrow, arrowWrapper, opened, wrapper } = useStyles();
  return (
    <Menu>
      <MenuButton as="div" className={dropdownTrigger}>
        {({ active }) => (
          <div className={clsx(wrapper, { active })}>
            {trigger}
            {withArrow && (
              <span className={clsx(arrowWrapper, { [opened]: active })}>
                <ArrowDropIcon className={arrow} />
              </span>
            )}
          </div>
        )}
      </MenuButton>
      <MenuItems anchor="bottom end" className={dropdownContent}>
        {children}
      </MenuItems>
    </Menu>
  );
};

export default memo(Dropdown);
