import { PHONE_NUMBER_PREFIX, strengthRegexp } from 'constants/main';

export const shortenEmail = (email, prefixCount = 1) => {
  if (!email) return '';
  const [localPart, domainPart] = email.split('@');
  if (localPart.length <= prefixCount) {
    return email;
  }
  const shortenedLocalPart = `${localPart.slice(0, prefixCount)}...`;
  return `${shortenedLocalPart}@${domainPart}`;
};

export const maskPhoneNumber = phone => {
  const phoneWithoutMask = phone.replace(/[()\s]/g, '');
  const countryCode = phoneWithoutMask.slice(0, phoneWithoutMask.length - 10);
  const localPart = phoneWithoutMask.slice(-10);
  const maskedLocalPart = `••• ••• ${localPart.slice(-4)}`;
  return `${countryCode} ${maskedLocalPart}`;
};

export const getPasswordStrength = password =>
  Object.entries(strengthRegexp).reduce((acc, [regexpKey, regexp]) => {
    acc[regexpKey] = regexp.test(password);

    return acc;
  }, {});

export const formatPhoneNumber = input => {
  const digits = input.replace(/\D/g, '');
  const lastTenDigits = digits.slice(-10);

  return `${PHONE_NUMBER_PREFIX} (${lastTenDigits.slice(0, 3)}) ${lastTenDigits.slice(3, 6)} ${lastTenDigits.slice(6)}`;
};

export const getAddress = data =>
  `${data.street}, ${data.apartment}, ${data.city}, ${data.stateCode} ${data.postalCode}`;

export const shortenString = (string, start = 1, end = 2) => {
  if (!string) return '';
  if (string.length <= start + end) {
    return string;
  }
  const shortenedStart = string.slice(0, start);
  const shortenedEnd = string.slice(-end);
  return `${shortenedStart}•••${shortenedEnd}`;
};

export const kilobytesToMegabytes = (size, precision = 2) => {
  const megabytes = size / 1024;
  return megabytes.toFixed(precision);
};

export const secondsToMinutes = seconds => {
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  return `${minutes < 10 ? `0${minutes}` : minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
};
