import { lazy } from 'react';

import { withSuspense } from 'utils';
import {
  ACCESSIBILITY,
  ACCOUNT,
  CALENDAR,
  FACILITIES,
  HELP_CENTER,
  MEMBERSHIP,
  NOT_FOUND,
  NOTIFICATION,
  PATIENTS,
  PLATFORM,
  PRIVACY,
  PROFILE,
  REFERRALS,
  SECURITY,
  SETTINGS,
  SMART_CONNECT,
  TRANSCRIPTIONS,
  WALLET,
} from 'constants/path';

export const routes = [
  {
    path: '/',
    Component: withSuspense(lazy(() => import('pages/main'))),
  },
  {
    path: SETTINGS,
    Component: withSuspense(lazy(() => import('pages/settings'))),
    children: [
      {
        path: PROFILE,
        Component: withSuspense(lazy(() => import('pages/settings/profileCompliance'))),
      },
      {
        path: ACCOUNT,
        Component: withSuspense(lazy(() => import('pages/settings/account'))),
      },
      {
        path: PLATFORM,
        Component: withSuspense(lazy(() => import('pages/settings/platform'))),
      },
      {
        path: SECURITY,
        Component: withSuspense(lazy(() => import('pages/settings/security'))),
      },
      {
        path: PRIVACY,
        Component: withSuspense(lazy(() => import('pages/settings/privacy'))),
      },
      {
        path: NOTIFICATION,
        Component: withSuspense(lazy(() => import('pages/settings/notification'))),
      },
      {
        path: ACCESSIBILITY,
        Component: withSuspense(lazy(() => import('pages/settings/notification'))),
      },
    ],
  },
  {
    path: SMART_CONNECT,
    Component: withSuspense(lazy(() => import('pages/smartConnect'))),
    children: [{ path: HELP_CENTER }],
  },
  {
    path: TRANSCRIPTIONS,
    Component: withSuspense(lazy(() => import('pages/transcriptions'))),
  },
  {
    path: CALENDAR,
    Component: withSuspense(lazy(() => import('pages/calendar'))),
  },
  {
    path: FACILITIES,
    Component: withSuspense(lazy(() => import('pages/facilities'))),
  },
  {
    path: PATIENTS,
    Component: withSuspense(lazy(() => import('pages/patients'))),
  },
  {
    path: WALLET,
    Component: withSuspense(lazy(() => import('pages/wallet'))),
  },
  {
    path: MEMBERSHIP,
    Component: withSuspense(lazy(() => import('pages/membership'))),
  },
  {
    path: REFERRALS,
    Component: withSuspense(lazy(() => import('pages/referrals'))),
  },
  {
    path: NOT_FOUND,
    Component: withSuspense(lazy(() => import('pages/404'))),
  },
];
