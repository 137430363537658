import Badge from 'components/badge';
import {
  ACCESSIBILITY,
  ACCOUNT,
  CALENDAR,
  DASHBOARD,
  FACILITIES,
  MEMBERSHIP,
  NOTIFICATION,
  PATIENTS,
  PLATFORM,
  PRIVACY,
  REFERRALS,
  SCHEDULE,
  SECURITY,
  SETTINGS,
  SMART_CONNECT,
  TRANSCRIPTIONS,
  TASKS,
  WALLET,
  PROFILE,
} from 'constants/path';
import { ReactComponent as TaskIcon } from 'assets/icons/twoTone/Tasks.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/twoTone/Wallet.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/twoTone/Calendar.svg';
import { ReactComponent as PatientsIcon } from 'assets/icons/twoTone/Patients.svg';
import { ReactComponent as ScheduleIcon } from 'assets/icons/twoTone/Schedule.svg';
import { ReactComponent as DashboardIcon } from 'assets/icons/twoTone/Dashboard.svg';
import { ReactComponent as ReferralsIcon } from 'assets/icons/twoTone/Referrals.svg';
import { ReactComponent as MembershipIcon } from 'assets/icons/twoTone/Membership.svg';
import { ReactComponent as FacilitiesIcon } from 'assets/icons/twoTone/Facilities.svg';
import { ReactComponent as SmartConnectIcon } from 'assets/icons/twoTone/SmartConnect.svg';
import { ReactComponent as CheckCircle } from 'assets/icons/twoTone/CheckCircle.svg';
import { ReactComponent as AccountIcon } from 'assets/icons/twoTone/Account.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/twoTone/Settings.svg';
import { ReactComponent as SecurityIcon } from 'assets/icons/twoTone/Security.svg';
import { ReactComponent as PrivacyIcon } from 'assets/icons/twoTone/Privacy.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/twoTone/Notification.svg';
import { ReactComponent as AccessibilityIcon } from 'assets/icons/twoTone/Accessibility.svg';
import { ReactComponent as TranscriptionsIcon } from 'assets/icons/twoTone/Transcriptions.svg';

export const navigation = [
  [
    {
      label: 'dashboard',
      href: DASHBOARD,
      Icon: DashboardIcon,
    },
    {
      label: 'calendar',
      href: CALENDAR,
      Icon: CalendarIcon,
    },
    {
      label: 'schedule',
      Icon: ScheduleIcon,
      subMenus: [
        { label: 'Schedule 1', href: SCHEDULE },
        { label: 'Schedule 2', href: SCHEDULE },
        { label: 'Schedule 3', href: SCHEDULE },
      ],
    },
    {
      label: (
        <>
          Tasks <Badge status="primary">3</Badge>{' '}
        </>
      ),
      Icon: TaskIcon,
      subMenus: [
        { label: 'Task 1', href: TASKS },
        { label: 'Task 2', href: TASKS },
        { label: 'Task 3', href: TASKS },
        { label: 'Task 4', href: TASKS },
        { label: 'Task 5', href: TASKS },
      ],
    },
  ],
  [
    {
      label: 'facilities',
      href: FACILITIES,
      Icon: FacilitiesIcon,
    },
    {
      label: 'patients',
      href: PATIENTS,
      Icon: PatientsIcon,
    },
    {
      label: 'smart_connect',
      href: SMART_CONNECT,
      Icon: SmartConnectIcon,
    },
    {
      label: 'transcriptions',
      href: TRANSCRIPTIONS,
      Icon: TranscriptionsIcon,
    },
  ],
  [
    {
      label: 'wallet',
      href: WALLET,
      Icon: WalletIcon,
    },
    {
      label: 'membership',
      href: MEMBERSHIP,
      Icon: MembershipIcon,
    },
    {
      label: 'referrals',
      href: REFERRALS,
      Icon: ReferralsIcon,
    },
  ],
];

export const settingsNavigation = [
  {
    label: 'profile_compliance',
    href: PROFILE,
    Icon: CheckCircle,
  },
  {
    label: 'account',
    href: ACCOUNT,
    Icon: AccountIcon,
  },
  {
    label: 'platform',
    href: PLATFORM,
    Icon: SettingsIcon,
  },
  {
    label: 'security',
    href: SECURITY,
    Icon: SecurityIcon,
  },
  {
    label: 'data_privacy',
    href: PRIVACY,
    Icon: PrivacyIcon,
  },
  {
    label: 'notification',
    href: NOTIFICATION,
    Icon: NotificationIcon,
  },
  {
    label: 'accessibility',
    href: ACCESSIBILITY,
    Icon: AccessibilityIcon,
  },
];
