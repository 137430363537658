export const getBase = store => store.base;
export const getBaseLang = store => getBase(store).lang;

export const getAuth = store => store.auth;
export const getAuthId = store => getAuth(store).id;
export const getRole = store => getAuth(store).role;
export const getImage = store => getAuth(store).image;
export const getFullName = store => getAuth(store).fullName;
export const getPhoneNumber = store => getAuth(store).phoneNumber;
export const getCompanyName = store => getAuth(store).companyName;
export const getProfessions = store => getAuth(store).professions;
export const getSelectedProfessions = store => getAuth(store).selectedProfessions;

export const getChat = store => store.chat;
export const getActiveTab = store => getChat(store).activeTab;
export const getUserSidebar = store => getChat(store).userSidebar;
export const getUserImages = store => getChat(store).userImages;
export const getConversationName = store => getChat(store).conversationName;
export const getLayoutMode = store => getChat(store).layoutMode;
export const getActiveUser = store => getChat(store).activeUser;
export const getUsers = store => getChat(store).users;
export const getGroups = store => getChat(store).groups;
export const getMessages = store => getChat(store).messages;
export const getIsChatLoading = store => getChat(store).isChatLoading;
export const getIsMessagesLoading = store => getChat(store).isMessagesLoading;
export const getAllChatUsers = store => getUsers(store).flatMap(({ users }) => users);

export const getSelectedUser = store => {
  const users = getUsers(store);
  const groups = getGroups(store);
  const activeUser = getActiveUser(store);

  return users.find(({ _id }) => _id === activeUser) || groups.find(({ _id }) => _id === activeUser);
};

const sortContact = contacts => {
  let data = contacts.reduce((r, e) => {
    try {
      // get first letter of name of current element
      let group = e.name[0];
      // if there is no property in accumulator with this letter create it
      if (!r[group]) r[group] = { group, children: [e] };
      // if there is push current element to children array for that letter
      else r[group].children.push(e);
    } catch (error) {
      return {
        group: 'A',
        children: [{ id: 0, name: 'Demo' }],
      };
    }
    // return accumulator
    return r;
  }, {});

  // since data at this point is an object, to get array of values
  // we use Object.values method
  return Object.values(data);
};

export const getContacts = store => {
  const { contacts } = store.chat;

  return sortContact(contacts);
};
