import { memo } from 'react';
import clsx from 'clsx';

import Typography from 'components/typography';
import { useTranslator } from 'utils/translator';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';

import { useStyles } from './styles';

const SearchInput = ({ className, value, showHotkey, ...rest }) => {
  const { t } = useTranslator();

  const { root, input, searchIcon, hotKey, active } = useStyles();

  return (
    <label className={clsx(className, root)}>
      <SearchIcon className={searchIcon} />
      <input type="text" className={clsx(input, { [active]: value })} {...rest} />
      {showHotkey && (
        <div className={hotKey}>
          <Typography variant="labelL" bold>
            {value ? 'Esc' : 'F'}
          </Typography>
        </div>
      )}
    </label>
  );
};

export default memo(SearchInput);
