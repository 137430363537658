export const CONTACT_ADD_STEP = 1;
export const CONTACT_VERIFICATION_STEP = 2;

export const CONTACT_VERIFICATION_CODE_LENGTH = 6;

export const PHONE_NUMBER_VERIFICATION_CODE_LENGTH = 6;

export const PHONE_NUMBER_PREFIX = '+1';

export const CLINIC = 'clinic';

export const selectDefaultOptions = [
  {
    label: 'Label 1',
    value: 'Value 1',
  },
  {
    label: 'Label 2',
    value: 'Value 2',
  },
  {
    label: 'Label 3',
    value: 'Value 3',
  },
];

export const NUMBER_REGEXP = /[0-9]/;
export const UPPERCASE_REGEXP = /[A-Z]/;
export const CHARACTER_LENGTH_REGEXP = /.{8,}/;
export const SYMBOLS_REGEXP = /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/;
export const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
export const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;

export const PHONE_NUMBER_MASK = '+1 (999) 999 9999';

export const strengthRegexp = {
  uppercase: UPPERCASE_REGEXP,
  number: NUMBER_REGEXP,
  symbol: SYMBOLS_REGEXP,
  length: CHARACTER_LENGTH_REGEXP,
};

export const STRENGTH_MAPPING = [
  { value: 'uppercase', text: 'uppercase' },
  { value: 'symbol', text: 'special_char' },
  { value: 'number', text: 'number' },
  { value: 'length', text: '8_letters' },
];

export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'hh:mm A';
export const TIME_FORMAT_FULL = 'HH:mm:ss';

export const COMPASS_DIRECTIONS = {
  N: { label: 'North', value: 'north' },
  NE: { label: 'Northeast', value: 'northeast' },
  E: { label: 'East', value: 'east' },
  SE: { label: 'Southeast', value: 'southeast' },
  S: { label: 'South', value: 'south' },
  SW: { label: 'Southwest', value: 'southwest' },
  W: { label: 'West', value: 'west' },
  NW: { label: 'Northwest', value: 'northwest' },
};
