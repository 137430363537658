import { useRef, useState, useCallback } from 'react';

const useSpeechRecognition = () => {
  const recognitionRef = useRef(null);

  const [text, setText] = useState('');
  const [isActive, setIsActive] = useState(false);

  const handleOnRecord = useCallback(() => {
    if (!recognitionRef.current) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      recognitionRef.current = new SpeechRecognition();
    }

    if (isActive) {
      recognitionRef.current.stop();
      setIsActive(false);

      return;
    }

    recognitionRef.current.onstart = function () {
      setIsActive(true);
    };

    recognitionRef.current.onend = function () {
      setIsActive(false);
    };

    recognitionRef.current.onresult = async function (event) {
      const transcript = event.results[0][0].transcript;

      setText(transcript);
    };

    recognitionRef.current.start();
  }, [isActive]);

  return { text, isActive, handleOnRecord };
};

export default useSpeechRecognition;
