import { memo } from 'react';
import { MenuItem } from '@headlessui/react';
import clsx from 'clsx';

import Typography from 'components/typography';

import { useStyles } from './styles';

const DropdownOption = ({ label, className, Icon, isActive, ...rest }) => {
  const { optionItem, optionIcon, optionWrapper } = useStyles();
  return (
    <MenuItem className={optionWrapper} as="div" {...rest}>
      <div className={clsx(optionItem, className, { active: isActive })}>
        {Icon && <Icon className={optionIcon} />}
        <Typography variant="bodyM">{label}</Typography>
      </div>
    </MenuItem>
  );
};

export default memo(DropdownOption);
