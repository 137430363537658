export const DASHBOARD = '/';
export const CALENDAR = '/calendar';
export const SCHEDULE = '/schedule';
export const TASKS = '/tasks';
export const FACILITIES = '/facilities';
export const PATIENTS = '/patients';
export const SMART_CONNECT = '/smart-connect';
export const TRANSCRIPTIONS = '/transcriptions';
export const WALLET = '/wallet';
export const MEMBERSHIP = '/membership';
export const REFERRALS = '/referrals';
export const SETTINGS = '/settings';
export const PROFILE = '/settings/profile';
export const ACCOUNT = '/settings/account';
export const PLATFORM = '/settings/platform';
export const SECURITY = '/settings/security';
export const PRIVACY = '/settings/privacy';
export const NOTIFICATION = '/settings/notification';
export const ACCESSIBILITY = '/settings/accessibility';
export const HELP_CENTER = `${SMART_CONNECT}/help-center`;

// TODO - replace from env, just for deploy issue now
export const SIGN_IN_PATH = `${process.env.REACT_APP_AUTH_URI}/signin`;

export const NOT_FOUND = '*';
