import { memo } from 'react';

import { useStyles } from 'components/loader/styles';
import Spinner from 'components/loader/spinner';

const Loader = ({ overlay }) => {
  const { root } = useStyles({ overlay });

  return (
    <div className={root}>
      <Spinner />
    </div>
  );
};

export default memo(Loader);
