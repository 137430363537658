import { useState, useEffect } from 'react';

const useCopyToClipBoard = () => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const timerId = isCopied && setTimeout(() => setIsCopied(false), 1000);

    return () => clearTimeout(timerId);
  }, [isCopied]);

  const copyToClipboard = key => {
    window.navigator.clipboard
      .writeText(key)
      .then(() => setIsCopied(true))
      .catch(() => setIsCopied(false));
  };

  return [isCopied, copyToClipboard];
};

export default useCopyToClipBoard;
