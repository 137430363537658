import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(theme => ({
  root: {
    width: ({ size }) => size,
    height: ({ size }) => size,
    flexShrink: 0,
    borderRadius: 999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: ({ status, variant }) => theme.background[status][variant === 'light' ? 'main' : 'text'],
    overflow: 'hidden',
    '&.colored': {
      backgroundColor: ({ status, variant }) => theme.background[status][variant === 'light' ? 'tonalDark' : 'main'],
    },
  },
}));
