import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

import { eraseCookie, getCookie } from 'utils';

const whiteList = [];

const apiUrl = process.env.REACT_APP_API_URI;

const axiosInstance = axios.create({ baseURL: apiUrl });

axiosInstance.interceptors.request.use(
  async configs => {
    const token = getCookie('accessToken');

    if (whiteList.every(e => configs?.url?.indexOf(e) === -1)) {
      if (token) {
        const { exp } = jwtDecode(token);

        if (Date.now() > exp * 1000) {
          // TODO::: create refresh token functional
        }
      }

      configs.headers.Authorization = `Bearer ${token}`;
    }
    return {
      ...configs,
    };
  },
  err => {
    return err;
  },
);

axiosInstance.interceptors.response.use(
  res => res,
  err => {
    if (err.response?.status === 401) {
      eraseCookie('accessToken');
      eraseCookie('refreshToken');
      // window.location.href = 'login';
    } else if (err.response?.status === 404) {
      // window.location.href = '/404';
    }
    throw err;
  },
);

export default axiosInstance;
