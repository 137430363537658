import { memo } from 'react';
import clsx from 'clsx';

import Typography from 'components/typography';
import { badgeTextSizes } from 'constants/styles';

import { useStyles } from './styles';

const Avatar = ({ img, children, status = 'primary', variant = 'light', className, size = 64, ...rest }) => {
  const { root } = useStyles({ status, size, variant });

  return (
    <div
      className={clsx(root, className, 'avatar', { colored: !img })}
      {...(img && { style: { backgroundImage: `url('${img}')` } })}
      {...rest}
    >
      {!img && (
        <Typography variant={badgeTextSizes[size] || 'headlineL'} bold>
          {children}
        </Typography>
      )}
    </div>
  );
};

export default memo(Avatar);
