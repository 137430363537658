import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(theme => ({
  root: {
    color: theme.palette.white,
    borderRadius: 999,
    '&:not($small)': {
      height: 16,
      minWidth: 16,
      maxWidth: 'max-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 4px',
    },
  },
  error: {
    background: theme.palette.error,
  },
  success: {
    background: theme.palette.success,
  },
  primary: {
    background: theme.palette.primary,
  },
  light: {
    background: theme.colors.blue[60],
  },
  warning: {
    background: theme.colors.yellow[40],
    color: theme.colors.grey[10],
  },

  small: {
    width: 6,
    height: 6,
  },
}));
