import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { LoadScript } from '@react-google-maps/api';

import { store } from 'store';
import { TranslatorProvider } from 'utils/translator';
import { ThemeWrapper } from 'config/theme';
import { SocketProvider } from 'connection/socket';
import { idmClient } from 'connection/graphql/apolloClients';

import './index.css';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ApolloProvider client={idmClient}>
    <Provider store={store}>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <TranslatorProvider>
          <SocketProvider>
            <ThemeWrapper>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </ThemeWrapper>
          </SocketProvider>
        </TranslatorProvider>
      </LoadScript>
    </Provider>
  </ApolloProvider>,
);
