import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

import { routes } from 'router';
import Layout from 'sections/layout';
import Loader from 'components/loader';
import { useMount } from 'utils/hooks';
import { getCookie } from 'utils';
import { getUserByToken } from 'connection/graphql/user';
import { SIGN_IN_PATH } from 'constants/path';
import { saveUserData } from 'store/reducers/auth';
import { ToastBar } from 'components/toast';

import './index.css';

function App() {
  const dispatch = useDispatch();

  const { data, loading } = useQuery(getUserByToken);

  useEffect(() => {
    if (data?.getUserByToken) {
      dispatch(saveUserData(data?.getUserByToken));
    }
  }, [data?.getUserByToken, dispatch]);

  useMount(() => {
    const accessToken = getCookie('accessToken');
    if (!accessToken) {
      window.location.href = SIGN_IN_PATH;
    }
  });

  if (loading) {
    return <Loader overlay />;
  }

  return (
    <div className="root">
      <main>
        <Routes>
          <Route element={<Layout />}>
            {routes.map(({ Component, path, children }) =>
              children?.length ? (
                <Route key={path} path={path} element={<Component />}>
                  {children.map(({ Component: ChildComponent, path: childPath }) => (
                    <Route key={childPath} path={childPath} element={<ChildComponent />} />
                  ))}
                </Route>
              ) : (
                <Route key={path} path={path} element={<Component />} />
              ),
            )}
          </Route>
        </Routes>
      </main>
      <ToastBar />
    </div>
  );
}

export default App;
