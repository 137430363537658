import { memo, useState } from 'react';

import SearchInput from 'components/search';
import ActionButton from 'components/actionButton';
import { ReactComponent as NotificationIcon } from 'assets/icons/Notification.svg';
import { ReactComponent as NurselinkLogoSmall } from 'assets/icons/Logo-small.svg';
import { useTranslator } from 'utils/translator';

import ProfileMenu from './ProfileMenu';
import { useStyles } from './styles';

const Header = () => {
  const { t } = useTranslator();
  const { root, headerLeft, headerRight, headerLogo } = useStyles();
  const [search, setSearch] = useState('');

  return (
    <div className={root}>
      <div className={headerLogo}>
        <NurselinkLogoSmall />
      </div>
      <div className={headerLeft}>
        <SearchInput
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder={t('search_doctors_services_placeholder')}
          showHotkey
        />
      </div>
      <div className={headerRight}>
        <ActionButton Icon={NotificationIcon} withBadge />
        <ProfileMenu />
      </div>
    </div>
  );
};

export default memo(Header);
