import { memo } from 'react';
import { MenuSeparator } from '@headlessui/react';

import { useStyles } from './styles';

const DropdownSeparator = () => {
  const { separator } = useStyles();

  return <MenuSeparator className={separator} />;
};

export default memo(DropdownSeparator);
