import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(theme => ({
  root: {
    position: 'relative',
    outline: 'none',
    border: 'none',
    width: 44,
    height: 44,
    flexShrink: 0,
    borderRadius: 12,
    background: theme.colors.grey[95],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.grey[10],
    transition: 'all ease .3s',
    cursor: 'pointer',
    '&:hover': {
      background: '#EBEFF2',
    },
    '&:disabled': {
      opacity: 0.4,
    },
  },
  badge: {
    position: 'absolute',
    top: 6,
    right: 6,
  },
}));
