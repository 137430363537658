import base from './base';
import auth from './auth';
import chat from './chat';

const reducers = {
  auth,
  base,
  chat,
};

export default reducers;
