import { Link } from 'react-router-dom';

import { navigation } from 'config';
import { DASHBOARD } from 'constants/path';
import { ReactComponent as NurselinkLogo } from 'assets/images/NurselinkLogo.svg';

import MenuItem from './MenuItem';
import { useStyles } from './styles';

const Sidebar = () => {
  const { root, sidebarLogo, sidebarContent, sidebarGroup } = useStyles();
  return (
    <div className={root}>
      <div className={sidebarLogo}>
        <Link to={DASHBOARD}>
          <NurselinkLogo />
        </Link>
      </div>
      <div className={sidebarContent}>
        {navigation.map(route => (
          <div className={sidebarGroup} key={route[0].label}>
            {route.map(menu => (
              <MenuItem key={menu.label} {...menu} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
