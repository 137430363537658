import { memo } from 'react';
import clsx from 'clsx';

import Typography from 'components/typography';

import { useStyles } from './styles';

const Badge = ({ status = 'error', children, className, ...rest }) => {
  const { root, error, success, primary, warning, light, small } = useStyles();
  return (
    <div
      className={clsx(root, className, {
        [error]: status === 'error',
        [light]: status === 'light',
        [success]: status === 'success',
        [primary]: status === 'primary',
        [warning]: status === 'warning',
        [small]: children === undefined,
      })}
      {...rest}
    >
      <Typography variant="labelS" bold>
        {children}
      </Typography>
    </div>
  );
};

export default memo(Badge);
