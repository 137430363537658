import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { GET_USERS_PROFILE_IMAGE } from 'api';
import axios from 'connection/axios';

const initialState = {
  activeTab: 'chat',
  userSidebar: false,
  conversationName: 'Doris Brown',
  layoutMode: 'light',
  activeUser: null,
  users: [],
  groups: [],
  contacts: [],
  isChatLoading: true,
  isMessagesLoading: true,
};

function changeBodyAttribute(attribute, value) {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
}

export const getChats = createAsyncThunk('chat/getChats', async users => {
  try {
    const ids = users.flatMap(({ users }) => users).map(({ user_id }) => user_id);
    const { data } = ids.length ? await axios.post(GET_USERS_PROFILE_IMAGE, { ids }) : { data: [] };

    const userImages = data.reduce((acc, { id, image }) => {
      acc[id] = image?.path;

      return acc;
    }, {});

    return {
      users: users.map(chat => ({
        ...chat,
        profile_picture: userImages[chat.user_id] || null,
        profilePicture: userImages[chat.user_id] || null,
      })),
      userImages,
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
});

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setActiveUser: (state, action) => {
      state.isMessagesLoading = true;
      state.activeUser = action.payload;
    },
    setFullUser: (state, action) => {
      state.users = action.payload;
    },
    getMessages: (state, action) => {
      state.isMessagesLoading = false;
      state.messages = action.payload;
    },
    receiveMessage: (state, action) => {
      state.messages = [...state.messages, action.payload];
    },
    getRoomByContact: (state, action) => {
      state.isMessagesLoading = true;
      state.activeUser = action.payload.room_id;
    },
    getContacts: (state, action) => {
      state.contacts = action.payload || [];
    },
    appendMessage: (state, action) => {
      state.messages = [...state.messages, { ...action.payload, userType: 'sender', notSent: true }];
    },
    getGroups: (state, action) => {
      state.groups = action.payload;
    },
    createGroupChat: (state, action) => {
      state.groups.push(action.payload);
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    openUserSidebar: state => {
      state.userSidebar = true;
    },
    closeUserSidebar: state => {
      state.userSidebar = false;
    },
    changeLayoutMode: (state, action) => {
      const layoutMode = action.payload;

      changeBodyAttribute('data-bs-theme', layoutMode);
      localStorage.setItem('layoutMode', layoutMode);

      state.layoutMode = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getChats.fulfilled, (state, action) => {
      state.isChatLoading = false;
      state.users = action.payload.users;
      state.userImages = action.payload.userImages;
      state.activeUser = action.payload.users[0]?._id;
    });
  },
});

export const {
  setFullUser,
  createGroup,
  setActiveTab,
  appendMessage,
  setActiveUser,
  receiveMessage,
  openUserSidebar,
  closeUserSidebar,
  changeLayoutMode,
} = chatSlice.actions;

const { actions: sliceActions } = chatSlice;

export const actions = { ...sliceActions, getChats };

export default chatSlice.reducer;
