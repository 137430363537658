import { memo } from 'react';
import clsx from 'clsx';

import Badge from 'components/badge';

import { useStyles } from './styles';

const ActionButton = ({ className, Icon, withBadge }) => {
  const { root, badge } = useStyles();
  return (
    <button className={clsx(root, className)}>
      {<Icon />}
      {withBadge && <Badge status="error" className={badge} />}
    </button>
  );
};

export default memo(ActionButton);
